import React from 'react';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import data from './../../Data/cutters.json';

function Instructions() {
  return (
    <Table striped hover className="mt-4">
      <thead>
        <tr>
          <th>Vecka</th>
          <th>Namn</th>
        </tr>
      </thead>
      <tbody>
        {data.map((week) => (
          <tr key={week.week}>
            <td>{week.week}</td>
            <td>{week.name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default Instructions;
