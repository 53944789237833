import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import WeeklySchedule from './Components/WeeklySchedule/WeeklySchedule';
import { Container, Row, Col, Toast, Accordion } from 'react-bootstrap';
import cutters from './Data/cutters.json';
import grassCutterLogo from './Img/grass_cutter.png';
import Logo from './Img/logo.png';

function App() {

  const currentWeekNumber = GetWeekNumber(new Date());
  const currentGrassCutter = cutters.find(entry => entry.week === currentWeekNumber)?.name;
  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);

  return (
    <div className="App" style={{alignItems: "center"}}>
      { currentGrassCutter !== undefined && 
        <div className="GrassCutterToastContainer">
          <Toast style={{ "width": "100%" }}  show={showA} onClose={toggleShowA}>
            <Toast.Header>
              <img src={grassCutterLogo} alt="Grass cutter logo" style={{"width": "20px"}} />&nbsp;&nbsp;
              <strong className="me-auto">Meddelande - vecka {currentWeekNumber}</strong>
              <small></small>
            </Toast.Header>
            <Toast.Body>Veckans klippare är {currentGrassCutter}</Toast.Body>
          </Toast>
        </div>
      }
      <div className="InfoContainer" style={{paddingTop: "15px"}}>
        <Container>
          <Row style={{paddingBottom: "15px"}}>
            <Col xs={12} sm={4} md={3} lg={3} className="text-center">
              <img src={Logo} alt="Logo" style={{width: "120px"}} />          
            </Col>
            <Col xs={12} sm={8} md={9} lg={9} >
              <h2>Klippschema</h2>
              <p style={{"fontSize": "14px"}}>
                Tabellen visar årets klippschema för Olsnäs Bollklubb (<i>eller bouleklubb?</i>). Tanken är att vi håller klippningen aktiv mellan Maj - Oktober. Om någon vecka inte passar, ta kontakt med någon och byt.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
            <Accordion defaultActiveKey="1">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Förhållningsregler</Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <li style={{"fontSize": "14px"}}>Får man förhinder någon vecka kontaktar man helt enkelt någon annan på listan och byter vecka, annars kan ängen bli hög och svår att klippa för nästa på listan.</li>
                    <li style={{"fontSize": "14px"}}>För dem som inte vill köra med egen gräsklippare finns i boden en kraftig, självgående Klippo att tillgå.</li>
                    <li style={{"fontSize": "14px"}}>I boden finn även en uppmärkt 5 liters grön bensindunk som man kan använda till Klippon. Även i år kör vi på alkylatbensin som är miljövänligare och gör gräsklipparen mer lättstartad.</li>
                    <li style={{"fontSize": "14px"}}>Kodlås till boden finns för enkel tillgång.</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            </Col>
          </Row>          
          <Row>
            <Col>
              { true && <WeeklySchedule /> }
            </Col>
          </Row>          
        </Container>
      </div>
      <div style={{height: "15px"}}></div>
   </div>
  );
}

function GetWeekNumber(date) {
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  var weekNumber = Math.ceil((((date - yearStart) / 86400000) + 1) / 7);
  return weekNumber;
}

export default App;
